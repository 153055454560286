import { convertParamsToQueryString } from "@machineq/elements";
import { PageRoutes } from "@machineq/models";
import { useQueryString } from "@pwa/components/navigation";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

/**
 * This hook uses the base url and an object that is passed
 * to it, formats it to a valid query string and then pushes
 * the string to the URL.
 *
 * Any component that needs search should use that query string to
 * then parse and get it's values it needs for the API.
 *
 */
export const usePushUrlState = <
  QueryParamShape extends Record<
    string,
    string | number | boolean | undefined
  > = Record<string, string | number | boolean>
>(params?: {
  /**
   * The base URL that you want the parameters to be attached to
   * If the baseUrl doesn't exist, then it will use the existing
   * pathname as the baseUrl
   *
   * e.g.
   * ```
   * baseUrl = "/assets"
   *
   * // returns
   * `/assets?key=value&key2=value2&key3=value3
   * ```
   */
  baseUrl?: PageRoutes;
  preventHistoryEntry?: boolean;
}) => {
  const { queryObj } = useQueryString();
  const { push, replace, pathname } = useRouter();

  const urlControl = useMemo(
    () => (params?.preventHistoryEntry ? replace : push),
    [params?.preventHistoryEntry, push, replace]
  );

  /**
   * This function will convert two Objects (QueryParameter Objects)
   * into strings and then compare them. If they are different
   * it will either replace the URL or push the new URL to cause
   * a change in state in the app. The replace or push is governed
   * at hook instantiation
   */
  const replaceOrPush = useCallback<
    (
      oldParams: Record<string, string | number | boolean | undefined>,
      newParams: Record<string, string | number | boolean | undefined>
    ) => void
  >(
    (oldParams, newParams) => {
      const oldQueryString = convertParamsToQueryString(oldParams);
      const newQueryString = convertParamsToQueryString(newParams);

      const oldPath = `${pathname}${oldQueryString}`;
      const newPath = `${params?.baseUrl || pathname}${newQueryString}`;

      if (oldPath !== newPath) {
        urlControl(newPath, undefined, {
          shallow: true
        });
      }
    },
    [params?.baseUrl, pathname, urlControl]
  );

  /**
   * Takes in some params and an array of strings.
   * If the array of strings has a string that is also
   * a key of the passed query parameters, this function
   * will remove that key from that object.
   */
  const removeKeysFromQueryObj = useCallback<
    (params: QueryParamShape, keys: string[]) => QueryParamShape
  >((params, keys) => {
    if (keys.length === 0) {
      return params;
    }
    const filteredParams = Object.entries(params).reduce(
      (accum, [key, value]) => {
        if (!keys.includes(key)) {
          return { ...accum, [key]: value };
        }
        return accum;
      },
      {} as QueryParamShape
    );
    return filteredParams;
  }, []);

  /**
   * Takes in a query object and a few options.
   * Depending upon the options, this function
   * will format a new query object and forward it
   * onto the replaceOrPush function.
   *
   * It will either combine the existing params with the
   * passed params to "preserve" the existing query params
   * in the search
   *
   * - OR -
   *
   * It will ignore any existing params and use the passed
   * query params as the new params to forward onto the replaceOrPush
   * function
   */
  const pushUrlState = useCallback<
    (
      queryObj: QueryParamShape,
      options?: {
        preserveExistingParams?: boolean;
        removeKeysFromParams?: string[];
        defaults?: {
          ordering?: string;
        };
      }
    ) => void
  >(
    (passedQueryParams, options) => {
      const preserve = options?.preserveExistingParams || false;
      const keysToRemove = options?.removeKeysFromParams || [];
      const defaultValues = options?.defaults || {};

      // Combine the existing params with
      // the passed params to "preserve" the
      // previous params
      if (preserve) {
        const combinedQueryParams = removeKeysFromQueryObj(
          {
            ...defaultValues,
            ...queryObj,
            ...passedQueryParams
          },
          keysToRemove
        );
        replaceOrPush(queryObj, combinedQueryParams);
        return;
      }

      // ignore existing params and only transform
      // the passedQueryParams that were passed into the function
      replaceOrPush(
        queryObj,
        removeKeysFromQueryObj(
          {
            ...defaultValues,
            ...passedQueryParams
          },
          keysToRemove
        )
      );
    },
    [replaceOrPush, queryObj, removeKeysFromQueryObj]
  );

  return pushUrlState;
};
