import {
  GetAllRoomsApiQueryParams,
  GetAllRoomsApiResponse
} from "@machineq/models";
import { useGet } from "@pwa/features/api";

export const useGetRoomFilterData = (buildingId: string | undefined) => {
  const { data: rooms } = useGet<
    GetAllRoomsApiResponse,
    GetAllRoomsApiQueryParams
  >({
    root: "/room",
    queryParams: {
      building: buildingId,
      page_size: 10000
    }
  });
  return rooms;
};
