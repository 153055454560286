import {
  GetAllBuildingsApiQueryParams,
  GetAllBuildingsApiResponse
} from "@machineq/models";
import { useGet } from "@pwa/features/api";

export const useGetBuildingFilterData = (campusId: string | undefined) => {
  const { data: buildings } = useGet<
    GetAllBuildingsApiResponse,
    GetAllBuildingsApiQueryParams
  >({
    root: "/building",
    queryParams: {
      campus: campusId,
      page_size: 1000,
      ordering: "building_description"
    }
  });
  return buildings;
};
