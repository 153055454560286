import { FormFieldSelect, FormFieldSelectProps } from "@machineq/elements";
import { forwardRef } from "react";

import { useGetRoomFilterData } from "./filter.useGetRoomFilterData";

export type FilteRoomProps = Omit<FormFieldSelectProps, "children" | "id"> & {
  buildingId: string | undefined;
};

export const FilterRoom = forwardRef<HTMLSelectElement, FilteRoomProps>(
  function FilterRoom({ buildingId, mqSize = "dense", ...restProps }, ref) {
    const rooms = useGetRoomFilterData(buildingId);

    return (
      <FormFieldSelect
        {...restProps}
        mqSize={mqSize}
        name="room_id"
        id="room_id"
        ref={ref}
        disabled={!buildingId}
      >
        <option value="">All</option>
        {rooms?.results.features.map(({ id, properties }) => (
          <option value={id?.toString()} key={id}>
            {properties.room_description}
          </option>
        ))}
      </FormFieldSelect>
    );
  }
);
