import { FormFieldSelectMulti } from "@machineq/elements";
import { AssetSearchApiQueryParams } from "@machineq/models";
import { useQueryString } from "@pwa/components/navigation";
import React, { FC } from "react";

import { assetStatus } from "./asset-search.utils";

export const AssetSearchLandingFilterMultiSelect: FC = () => {
  const { queryObj } = useQueryString<AssetSearchApiQueryParams>();

  return (
    <FormFieldSelectMulti
      label="Status"
      options={assetStatus}
      name="status__in"
      initialValues={queryObj.status__in}
      mqSize="default"
    />
  );
};
