import {
  LayoutFeature,
  LayoutFeatureSection,
  LayoutFeatureSectionBody,
  LayoutFeatureSectionHeader
} from "@pwa/features/layout";
import { FeaturePageComponent, withAuthentication } from "@pwa/features/page";
import React from "react";

import { MQAppbar, MQAppbarRow, MQAppbarTitle } from "../appbar";
import { AssetSearchPageLayout } from "./AssetSearch.layout";
import { AssetSearchLanding } from "./AssetSearchLanding";

const Page: FeaturePageComponent = () => {
  return (
    <LayoutFeature>
      <LayoutFeatureSection>
        <LayoutFeatureSectionHeader>
          <MQAppbar>
            <MQAppbarRow>
              <MQAppbarTitle title="Assets" />
            </MQAppbarRow>
          </MQAppbar>
        </LayoutFeatureSectionHeader>
        <LayoutFeatureSectionBody>
          <AssetSearchLanding />
        </LayoutFeatureSectionBody>
      </LayoutFeatureSection>
    </LayoutFeature>
  );
};

Page.PageLayout = AssetSearchPageLayout;
Page.meta = {
  title: "Asset Search",
  description:
    "Search for a list of assets based upon specified search criteria",
  url: "/"
};

export const AssetSearchPageLanding = withAuthentication(Page);
