import { desktopStyles, makeColor, mobileStyles } from "@machineq/elements";
import {
  Button,
  ButtonGroup,
  FormFieldSearch,
  makeRem
} from "@machineq/elements";
import { AssetSearchApiQueryParams } from "@machineq/models";
import {
  FilterBuilding,
  FilterCampus,
  FilterRoom,
  useCampusBuildingRoomFilter
} from "@pwa/features/filter";
import { usePushUrlState } from "@pwa/hooks";
import React, { FC, FormEventHandler, useCallback, useRef } from "react";
import styled from "styled-components";

import { AssetSearchLandingFilterMultiSelect } from "./AssetSearchLandingFilterMultiSelect";

const spacing = makeRem(54);

const StyledCardContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  position: relative;

  ${mobileStyles} {
    overflow-y: auto;
  }

  ${desktopStyles} {
    padding: ${spacing};

    &::after {
      content: "";
      position: absolute;
      background: ${makeColor("grey-1")};
      top: 0;
      left: 0;
      right: 0;
      height: ${makeRem(270)};
      z-index: -1;
    }
  }
`;

const StyledSearchCard = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  && {
    ${mobileStyles} {
      max-width: ${makeRem(416)};
      height: 100%;
      width: 100%;
      box-shadow: none;
      align-items: flex-start;
    }

    ${desktopStyles} {
      position: absolute;
      max-width: ${makeRem(960)};
      margin-left: ${spacing};
      margin-right: ${spacing};
      align-items: flex-end;
      padding: ${makeRem(64)} ${makeRem(140)};
    }
  }
`;
const SForm = styled.form`
  width: 100%;
  ${mobileStyles} {
    padding: ${makeRem(32)};
  }
`;

const SGrid = styled.div`
  display: grid;
  grid-gap: ${makeRem(40)};
  ${mobileStyles} {
    grid-template-areas:
      "search"
      "status"
      "campus"
      "building"
      "room"
      "submit";
  }
  ${desktopStyles} {
    grid-template-areas:
      "search search status"
      "campus building room"
      "submit submit submit";
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const SGridSearch = styled.div`
  grid-area: search;
`;
const SGridStatus = styled.div`
  grid-area: status;
`;
const SGridCampus = styled.div`
  grid-area: campus;
`;
const SGridBuilding = styled.div`
  grid-area: building;
`;
const SGridRoom = styled.div`
  grid-area: room;
`;
const SGridSubmit = styled.div`
  grid-area: submit;

  ${mobileStyles} {
    button {
      width: 100%;
    }
  }

  ${desktopStyles} {
    text-align: center;
  }
`;

export const AssetSearchLanding: FC = () => {
  const {
    campusId,
    buildingId,
    handleChangeBuilding,
    handleChangeCampus,
    campusInputRef,
    buildingInputRef,
    roomInputRef
  } = useCampusBuildingRoomFilter();
  const updateUrl = usePushUrlState<
    Omit<AssetSearchApiQueryParams, "status__in"> & {
      status__in: string;
    }
  >({
    baseUrl: "/assets"
  });
  const formRef = useRef<HTMLFormElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      if (formRef.current) {
        const formData = new FormData(formRef.current);
        const entries = Object.fromEntries(formData.entries());
        const status__in = formData.get("status__in") as string;
        const params = {
          ...entries,
          status__in
        };
        updateUrl(params, {
          preserveExistingParams: true,
          removeKeysFromParams: ["row"],
          defaults: {
            ordering: "description"
          }
        });
      }
    },
    [updateUrl]
  );

  return (
    <StyledCardContainer>
      <StyledSearchCard>
        <SForm onSubmit={handleSubmit} ref={formRef}>
          <SGrid>
            <SGridSearch>
              <FormFieldSearch
                ref={inputRef}
                label="Search"
                id="search"
                data-test-id="search"
                name="search"
                placeholder="Asset ID or Description"
                aria-label="Search for assets by asset ID or description"
              />
            </SGridSearch>
            <SGridStatus>
              <AssetSearchLandingFilterMultiSelect />
            </SGridStatus>
            <SGridCampus>
              <FilterCampus
                label="Campus"
                ref={campusInputRef}
                onChange={handleChangeCampus}
                mqSize="default"
              />
            </SGridCampus>
            <SGridBuilding>
              <FilterBuilding
                label="Building"
                ref={buildingInputRef}
                onChange={handleChangeBuilding}
                campusId={campusId}
                mqSize="default"
              />
            </SGridBuilding>
            <SGridRoom>
              <FilterRoom
                label="Room"
                ref={roomInputRef}
                buildingId={buildingId}
                mqSize="default"
              />
            </SGridRoom>
            <SGridSubmit>
              <ButtonGroup mqLayout="inline" mqAlignment="center">
                <Button
                  mqVariant="contained"
                  mqColor="primary"
                  mqSize="large"
                  type="submit"
                >
                  Search
                </Button>
              </ButtonGroup>
            </SGridSubmit>
          </SGrid>
        </SForm>
      </StyledSearchCard>
    </StyledCardContainer>
  );
};
