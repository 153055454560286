import { GetAllCampusesApiResponse } from "@machineq/models";
import { useGet } from "@pwa/features/api";

export const useGetCampusFilterData = () => {
  const { data: campuses } = useGet<GetAllCampusesApiResponse>({
    root: "/campus",
    queryParams: { page_size: 1000 }
  });
  return campuses;
};
