import { useQueryString } from "@pwa/components/navigation";
import { useCallback, useEffect, useRef, useState } from "react";

export const useCampusBuildingRoomFilter = (
  options: {
    subscribeToURL?: boolean;
  } = {
    subscribeToURL: true
  }
) => {
  const { queryObj } = useQueryString<{
    campus_id?: string;
    building_id?: string;
    room_id?: string;
  }>();

  const campusInputRef = useRef<HTMLSelectElement | null>(null);
  const buildingInputRef = useRef<HTMLSelectElement | null>(null);
  const roomInputRef = useRef<HTMLSelectElement | null>(null);

  const [campusId, setCampusId] = useState<string>("");
  const [buildingId, setBuildingId] = useState<string>("");

  useEffect(() => {
    if (
      options?.subscribeToURL &&
      campusInputRef.current &&
      queryObj.campus_id
    ) {
      const campusId = queryObj.campus_id.toString();
      campusInputRef.current.value = campusId;
      setCampusId(campusId);
    }
  }, [options?.subscribeToURL, queryObj.campus_id]);

  useEffect(() => {
    if (
      options?.subscribeToURL &&
      buildingInputRef.current &&
      queryObj.building_id
    ) {
      const buildingId = queryObj.building_id.toString();
      buildingInputRef.current.value = buildingId;
      setBuildingId(buildingId);
    }
  }, [options?.subscribeToURL, queryObj.building_id]);

  useEffect(() => {
    if (options?.subscribeToURL && roomInputRef.current && queryObj.room_id) {
      const roomId = queryObj.room_id.toString();
      roomInputRef.current.value = roomId;
    }
  }, [options?.subscribeToURL, queryObj.room_id]);

  const handleChangeCampus = useCallback(() => {
    if (
      campusInputRef.current &&
      buildingInputRef.current &&
      roomInputRef.current
    ) {
      setCampusId(campusInputRef.current.value);
      buildingInputRef.current.value = "";
      roomInputRef.current.value = "";
    }
  }, [buildingInputRef, campusInputRef, roomInputRef]);

  const handleChangeBuilding = useCallback(() => {
    if (buildingInputRef.current && roomInputRef.current) {
      setBuildingId(buildingInputRef.current.value);
      roomInputRef.current.value = "";
    }
  }, [buildingInputRef, roomInputRef]);

  return {
    campusInputRef,
    buildingInputRef,
    roomInputRef,
    handleChangeCampus,
    handleChangeBuilding,
    campusId,
    buildingId
  };
};
