import { FormFieldSelect, FormFieldSelectProps } from "@machineq/elements";
import { forwardRef } from "react";

import { useGetCampusFilterData } from "./filter.useGetCampusFilterData";

export type FilterCampusProps = Omit<FormFieldSelectProps, "children" | "id">;

export const FilterCampus = forwardRef<HTMLSelectElement, FilterCampusProps>(
  function FilterCampus({ mqSize = "dense", ...restProps }, ref) {
    const campuses = useGetCampusFilterData();

    return (
      <FormFieldSelect
        {...restProps}
        mqSize={mqSize}
        name="campus_id"
        id="campus_id"
        ref={ref}
      >
        <option value="">All</option>
        {campuses?.results.features.map((campus) => (
          <option value={campus.id} key={campus.id}>
            {campus.properties.campus_description}
          </option>
        ))}
      </FormFieldSelect>
    );
  }
);
