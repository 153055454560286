import { FormFieldSelect, FormFieldSelectProps } from "@machineq/elements";
import { forwardRef } from "react";

import { useGetBuildingFilterData } from "./filter.useGetBuildingFilterData";

export type FilterBuildingProps = Omit<
  FormFieldSelectProps,
  "children" | "id"
> & {
  campusId: string | undefined;
};

export const FilterBuilding = forwardRef<
  HTMLSelectElement,
  FilterBuildingProps
>(function FilterBuilding({ campusId, mqSize = "dense", ...restProps }, ref) {
  const buildings = useGetBuildingFilterData(campusId);

  return (
    <FormFieldSelect
      {...restProps}
      mqSize={mqSize}
      name="building_id"
      id="building_id"
      ref={ref}
    >
      <option value="">All</option>
      {buildings?.results.features.map(({ id, properties }) => (
        <option value={id?.toString()} key={id}>
          {properties.building_description}
        </option>
      ))}
    </FormFieldSelect>
  );
});
