import { useRouter } from "next/router";

export const useQueryString = <QueryStringShape>(): {
  queryString: string;
  queryObj: Partial<QueryStringShape>;
} => {
  const { asPath, query } = useRouter();
  const string = asPath.split(/\?/)[1];

  const newQueryObj = Object.entries(query).reduce((accum, [key, value]) => {
    if (
      value?.includes(",") &&
      key.includes("__in") &&
      typeof value === "string"
    ) {
      return { ...accum, [key]: value.split(",") };
    }
    if (key.includes("__in") && typeof value === "string") {
      return { ...accum, [key]: [value] };
    }
    return { ...accum, [key]: value };
  }, {});

  return {
    queryString: string,
    queryObj: newQueryObj as unknown as Partial<QueryStringShape>
  };
};
